import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Button from 'reactstrap/lib/Button';
import classnames from 'classnames';
import Container from 'reactstrap/lib/Container';

import { getTitleWithNoOrphans } from 'site-modules/shared/utils/get-title-with-no-orphans';

import { Link } from 'site-modules/shared/components/link/link';
import { GlobalSearch } from 'site-modules/shared/components/inventory/global-search/global-search';
import { ContentFragment } from 'site-modules/shared/components/content-fragment/content-fragment';
import { ClippedContainer } from 'site-modules/shared/components/clipped-container/clipped-container';
import convexMaskMobile from 'site-modules/shared/components/clipped-container/mask-library/home-header-mobile';
import convexMaskDesktop from 'site-modules/shared/components/clipped-container/mask-library/convex-header';
import { FeatureFlag } from 'site-modules/shared/components/feature-flag/feature-flag';

import { Ads9843HeroGraphics } from './ads-9843-hero-graphics';

import './home-hero-inventory.scss';

const BUTTON_DATA = [
  {
    id: 'used_btn',
    label: 'Shop Used',
    url: '/used-all/',
  },
  {
    id: 'new_btn',
    label: 'Shop New',
    url: '/new-cars-for-sale/',
  },
  {
    id: 'appraise_btn',
    label: 'Appraise My Car',
    url: '/appraisal/',
  },
];

export function HomeHeroInventory({ trackingParent, isMobile, className, isAds9843Recipe }) {
  const isAds9843Chal = ['chal1', 'chal2'].includes(isAds9843Recipe);
  const ads9843CtasCtrl = () => (
    <div
      className={classnames('d-flex align-items-center justify-content-center buttons mb-1_5', {
        mobile: isMobile,
        desktop: !isMobile,
      })}
      data-tracking-parent="edm-entry-inventory-search"
    >
      {BUTTON_DATA.map(({ id, label, url }) => (
        <Button
          key={id}
          tag={Link}
          color="outline-blue-50"
          to={url}
          className={classnames(
            'pos-r d-flex align-items-center text-transform-none inventory-button px-0_5 py-0 rounded-8 bg-white',
            {
              medium: isMobile,
              'size-16': !isMobile,
            }
          )}
          data-tracking-id="home_page_inventory_select_tab"
        >
          {label}
        </Button>
      ))}
    </div>
  );

  const ads9843CtasChal = () => (
    <div
      className={classnames(
        'd-flex align-items-center justify-content-center justify-content-md-start buttons mb-1_5',
        {
          mobile: isMobile,
          desktop: !isMobile,
        }
      )}
      data-tracking-parent="edm-entry-inventory-search"
    >
      {BUTTON_DATA.map(({ id, label, url }) => (
        <Button
          key={id}
          tag={Link}
          color="outline-blue-50"
          to={url}
          className={classnames(
            'pos-r d-flex align-items-center text-transform-none inventory-button px-0_75 py-0_5 rounded-8 bg-white font-weight-normal',
            {
              medium: isMobile,
              'size-16': !isMobile,
            }
          )}
          data-tracking-id="home_page_inventory_select_tab"
        >
          {label}
        </Button>
      ))}
    </div>
  );

  const renderGlobalSearch = (disableLlm, isAds9843Enabled) => (
    <GlobalSearch isMobile={isMobile} withLlmSearchDisabled={disableLlm} isAds9843Enabled={isAds9843Enabled} />
  );

  const ads9843SearchCtrl = disableLlm => (
    <Fragment>
      <ClippedContainer
        containerClasses={classnames('homepage-gradient d-flex pos-a top-0 left-0 right-0', {
          mobile: isMobile,
        })}
        mask={isMobile ? convexMaskMobile : convexMaskDesktop}
      />
      <ContentFragment
        componentToUse="h1"
        classes={classnames('text-white text-center pos-r pt-1_5 pb-1_5 mb-0 title pt-md-3', {
          desktop: !isMobile,
        })}
      >
        {getTitleWithNoOrphans('Let’s find your perfect car')}
      </ContentFragment>
      {renderGlobalSearch(disableLlm, false)}
    </Fragment>
  );

  const ads9843SearchChal = (disableLlm, option) => {
    const isChal1 = option.recipe === 'chal1';
    const isChal2 = option.recipe === 'chal2';
    return (
      <Fragment>
        <ClippedContainer
          containerClasses={classnames('homepage-gradient d-flex pos-a top-0 left-0 right-0 is-ads-9843-chal', {
            mobile: isMobile,
            'is-chal2': isChal2,
          })}
          mask={isMobile ? convexMaskMobile : convexMaskDesktop}
        />
        <Container>
          <div className="is-ads-9843-chal d-md-flex">
            <div
              style={{
                flex: '1 1 auto',
              }}
              className={classnames({
                'mt-1': isChal2,
              })}
            >
              <h1
                className={classnames('text-white pos-r pt-1_5 pb-1_5 mb-0 text-center text-md-left', {
                  'pt-md-1': disableLlm,
                })}
              >
                <span className={classnames('tagline mb-0_75 mb-md-0_25 mb-xl-0_5 font-weight-medium d-block')}>
                  2.7 million vehicles for sale
                </span>
                <ContentFragment componentToUse="span" classes="title d-block">
                  {getTitleWithNoOrphans('Let’s find your perfect car')}
                </ContentFragment>
              </h1>
              {renderGlobalSearch(disableLlm, true)}
              {ads9843CtasChal(disableLlm)}
            </div>
            <Ads9843HeroGraphics isChal1={isChal1} isChal2={isChal2} />
          </div>
        </Container>
      </Fragment>
    );
  };

  return (
    <FeatureFlag name="disable-homepage-llm">
      {disableLlm => (
        <div
          className={classnames('home-hero-inventory pos-r p-0', className, {
            'overflow-x-clip': isAds9843Chal,
          })}
          data-tracking-parent={trackingParent}
        >
          <div className="pos-r px-1">
            {isAds9843Chal ? ads9843SearchChal(disableLlm, { recipe: isAds9843Recipe }) : ads9843SearchCtrl(disableLlm)}
          </div>
          {!isAds9843Chal && ads9843CtasCtrl(disableLlm)}
        </div>
      )}
    </FeatureFlag>
  );
}

HomeHeroInventory.propTypes = {
  trackingParent: PropTypes.string,
  isMobile: PropTypes.bool,
  className: PropTypes.string,
  isAds9843Recipe: PropTypes.string,
};

HomeHeroInventory.defaultProps = {
  trackingParent: null,
  isMobile: false,
  className: null,
  isAds9843Recipe: '',
};
